<template>
	<div>
		<v-navigation-drawer
			dark
      v-model="drawer"
      app
			:permanent = drawer
			clipped
			expand-on-hover
			width="200"
    >

		<v-list two-line subheader>

			<v-list-item>
        <v-list-item-content class="text-center">
          <v-list-item-title>
            {{userName}}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{role}}
						<br/>
						<v-btn small text  :to="{name: 'UserProfile'}">
							<v-icon class="ml-2" x-small>mdi-account</v-icon>View Profile
						</v-btn>
					</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item :to="{ name: 'Dashboard' }">
				<v-list-item-action>
					<v-icon>mdi-view-dashboard</v-icon>
				</v-list-item-action>
      	<v-list-item-content>
          <v-list-item-title >Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
				<!-- User -->
		<v-list>
      <v-list-item :to="{ name: 'UserView' }">
				<v-list-item-action>
					<v-icon>mdi-account-group</v-icon>
				</v-list-item-action>
      	<v-list-item-content>
          <v-list-item-title >USERS</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
				<!-- Project -->
		<v-list>
      <v-list-item :to="{ name: 'ProjectView' }">
				<v-list-item-action>
					<v-icon>mdi-card-text</v-icon>
				</v-list-item-action>
      	<v-list-item-content>
          <v-list-item-title >PROJECTS</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
				<!-- Test Model -->
		<v-list>
      <v-list-item :to="{ name: 'TmodelView' }">
				<v-list-item-action>
					<v-icon>mdi-chart-tree</v-icon>
				</v-list-item-action>
      	<v-list-item-content>
          <v-list-item-title >TEST MODELS</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>

		<template v-slot:append>

			<v-list>
				<v-list-item @click.stop="logout">
					<v-list-item-action>
						<v-icon>mdi-power-standby</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title >Logout</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<!-- <div class="pa-2">
				<v-btn outlined block rounded @click.stop="logout" color="grey" class="white--text">
					<v-icon class="mr-3">mdi-exit-to-app</v-icon>Logout
				</v-btn>
				<div class="d-flex flex-column mx-10 my-4">
					<span class="poweredby primary--text mr-2">Powered by</span>
					<v-img src="@/assets/logo-top.png" contain height="40" />
				</div>
			</div> -->

		</template>




		</v-navigation-drawer>

			<v-app-bar dark color="primary" app flat clipped-left>
				<v-app-bar-nav-icon @click.stop="drawer = !drawer">
					<v-img src="@/assets/isologo-white.png" contain max-height="40"/>
				</v-app-bar-nav-icon>
				<v-app-bar-title>
					<!-- <v-img  src="@/assets/logo-name-white.png" contain max-height="25"/> -->
					<div class="logo_title ml-3">Intellectum</div>
					<div class="logo_subtitle ml-3">Data Intelligence Platform</div>
				</v-app-bar-title>

				<!-- <v-app-bar-nav-icon  color="white"  @click.stop="drawer = !drawer" /> -->

				<!-- <v-btn class="mr-2 primary" fab small :to="{ name: 'Dashboard' }">
					<v-icon>mdi-view-dashboard</v-icon>
				</v-btn>
				<v-btn class="primary" fab small @click.stop="logout">
					<v-icon>mdi-exit-to-app </v-icon>
				</v-btn> -->
			</v-app-bar>
	</div>
</template>

<script>
	export default {
		name: 'ToolbarAdmin',
		components: {
			Footer: () => import('@/components/core/Footer')
		},
		data: () => ({
			drawer:null,
			permanente: true,
			settings: [],
		}),
		mounted(){
		},
		methods: {
			logout(){
				this.$store.dispatch("authModule/signOut",{root: true});
			}
		},
		computed: {
			role(){
				return this.$store.state.authModule.user.role
			},
			userName(){
				return this.email.substring(0, this.email.lastIndexOf("@"));
			},
			email(){
				return this.$store.state.authModule.user.email ? this.$store.state.authModule.user.email : 'User'
			}
		},

	}
</script>

<style scoped>
	.logo_title {
		font-size: 1.58em;
		font-weight: 800;
		line-height: 0.8em;
	}
	.logo_subtitle {
		font-size: 0.7em;
	}
	.poweredby {
		font-size: 0.6em;
		text-align: right;
	}
	.profile {
		color: white;
		text-decoration: none;
	}
	.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
		padding-left: 0px !important;
	}
</style>